<div class="container">
    <div class="title">Unser Team</div>
    <div class="text">In unserer Praxis arbeitet ein qualifiziertes und hochmotiviertes Team:<br><br><br>
        <b class="orange-bold">Dr. med. Joachim Schnell</b><br><br>
        <div class="first-info">
            <img style="width: 220px; height: 300px;" src="/assets/images/drschnell.JPG">
            <div class="picture-text">
                <ul>
                    <li>Facharzt für Innere Medizin (Internist), Universität Frankfurt/Main</li>
                    <li>Zugelassen für die hausärztliche Versorgung</li>
                    <li>Arzt für Notfallmedizin als Zusatzbezeichnung</li>
                </ul>
            </div>
        </div>
        <div class="spacer-small"></div>
        <div>„Hausarzt werden wollte ich schon immer“, mit diesem Satz kann man Dr. Schnell am besten beschreiben. Mit
            Leib und Seele Landarzt im schönen Wehrheim, und im 3. Jahrzehnt der Praxisführung kennt er schon mehrere
            Generationen!
            Im kollegialen Verbund mit den Haus- und Facharztkollegen sowie den Krankenhäusern und Apotheken der
            Umgebung hat er er ein breites medizinisches Netzwerk zur Verfügung. Die Praxis wird von ihm auf dem
            aktuellen technischen und medizinischen Niveau geführt. Die Erfüllung aller modernen Anforderungen (zB
            e-Rezept, online- Medikamentencheck, e-Arztbrief, etc.) und regelmäßige Fortbildung halten die Praxis auf
            aktuellen und modernen Standard. <br><br>
            In seiner Freizeit geht er am liebsten auf Wandertour und macht Musik auf allerlei Instrumenten.
        </div>
        <div class="spacer-small"></div>
        Mehr Informationen zur Ausbildung und zum Werdegang von Dr. med. Joachim Schnell finden sie <a
            routerLink='/drschnell'>hier</a>.
        <div class="spacer"></div>
        <b class="orange-bold">Yvonne Velte</b><br><br>
        <ul>
            <li>Arzthelferin</li>
        </ul>
        <div class="spacer-small"></div>
        Als Mitarbeiterin der ersten Stunde arbeitet Sie am liebsten am Patienten, sei es im Labor zur Blutentnahme,
        Impfen oder Versorgen von Wunden. Darüber hinaus ist sie ein echtes Multitalent und kennt sie sich hervorragend
        in der IT aus, weiß um alle Fallstricke der Abrechnung und ist Ansprechparterin für viele weitere Bereiche. Ihr
        Herz schläft besonders für die älteren Patienten, für die Sie immer eine Lösung findet. Keine Arbeit ist ihr zu
        viel und selbst im Urlaub sorgt Sie sich um die Praxis.<br><br>
        Ist sie mal nicht in der Praxis, ist sie am liebsten mit ihren Hunden zusammen
        <div class="spacer"></div>
        <b class="orange-bold">Stefanie Beyerbach</b><br><br>
        <ul>
            <li>Arzthelferin</li>
        </ul>
        <div class="spacer-small"></div>
        Als „Neueinsteigerin“ in unserer Praxis hat sie sich als Ur-Wehrheimerin mit großem Engagement und Fleiß schnell in die umfangreiche Materie einer Hausarztpraxis eingearbeitet. Bei Terminvereinbarungen und Rezeptbestellungen ist sie eine der freundlichen Stimmen am Telefon, kennst sich bestens in administrativer Dinge aus und führt apparative Untersichungen aus, kurz: Sie ist aus dem Praxisalltag nicht mehr wegzudenken. <br><br>
        Ihre größte Freude ist ihr Enkel
        <div class="spacer"></div>
        <b class="orange-bold">Karin Zoder</b><br><br>
        <ul>
            <li>Arzthelferin</li>
        </ul>
        <div class="spacer-small"></div>
        Seit drei Jahrzehnten in der Praxis kennt sie sich bestens im Qualitätsmanagement aus, betreut unsere chronisch
        erkrankten Patienten in den strukturierten Gesundheitsprogrammen (desease management program) und springt immer
        ein, wenn es mal hektisch wird. Mit ihrer ruhigen und fürsorglichen Art umsorgt sie die ganze Praxis.<br><br>
        Zu Hause fühlt sie sich im Kreise der Großfamilie am wohlsten.
    </div>
    <div class="spacer-end"></div>
</div>